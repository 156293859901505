import {addCallback, addReducer, setGlobal} from 'reactn';
import moment from "moment";
import {refetchEvents} from "./components/Cal/connectWs";
import axios from 'axios'
import store from "store";
import {USER_INFO_KEY} from "./apis/constants";
import query from "./common/query";
import {ScheduleChangeable} from "./common/ScheduleChangeable";
import {toast} from 'react-toastify'
import {ScheduleResizaable} from "./common/ScheduleResizeable";
// console.log(store.get(USER_INFO_KEY))
// Set an initial global state directly:
addCallback(g => {
    if (process.env.NODE_ENV === 'development')
        console.log(g)
})
setGlobal({
    calIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(i => 'Cal_' + i),
    numOfCals: 1,
    startDefaultDate: moment(),
    calsDuration: {month: 1},
    eventClickInfo: {},
    skillListChecked: [],
    schedules: [],
    selServiceName: 'innertours',
    user: {},
    allCodes: ((store.get(USER_INFO_KEY) || {}).skills || []).map(s => s.code),
    activeService: query('service') || 'all',
    eventMouseEnterInfo: {},
    subscriptions: [],
    allSubs: [],
    elArr: [],
    QuestumTitle: '',
    QuestumImage: '',
    descr: '',
    loading: false,
    categories:[],
    categoriesSelected:[],
    coma:1,
    anchorEl:null,
    openTable:false,
    rrule: [],
    isRepeat: false,
    delRecurrenPopup: false,
    openChangePassword: false,
    RejectSub_uuid: '',
    returnTicket: '',
    ReturnServices: '',
    rankPrices: [],
    userCoins: '',
    UserReject_uuid: '',
    showCalendar: parseInt(localStorage.getItem('showCalendarEvents') || 1),
    showAvatarUploader: false,
    ggCalInit: false,
    googleCalendarInit: false,
    googleCalendars: []
});


addReducer('addSchedule', (g, dispatch, schedule) => {
    const schedules = [...(g.schedules), schedule]
    // console.log(schedules)
    return { schedules }
})
addReducer('deleteSchedule', (g, dispatch, uuid) => {

    const newSchedules = [...g.schedules]
    let idx = -1
    newSchedules.find((s, i) => {
        const match = s.uuid === uuid
        if (match) {
            idx = i
        }
        return match
    })

    if (idx > -1) {
        // console.log(deletedSchedule)
        newSchedules.splice(idx, 1)
    }

    return { schedules: newSchedules }
})
addReducer('updateSchedule', (g, dispatch, schedule) => {
    const newSchedules = [...g.schedules]
    let idx = -1
    newSchedules.find((s, i) => {
        const match = s.uuid === schedule.uuid
        if (match) {
            idx = i
        }
        return match
    })

    if (idx > -1) {
        newSchedules.splice(idx, 1, schedule)
    }

    return { schedules: newSchedules }
})

addReducer('resizeTime', async (g, dispatch, eventResizeInfo) => {
    // console.log(eventResizeInfo)

    try {
        const { endDelta, event: {start,end, extendedProps: { uuid ,subscriptions } } } = eventResizeInfo
        // console.log(endDelta, uuid)
        if (ScheduleResizaable(start,end,subscriptions)) {
            await axios.put('/schedule/resizeTime',
                { endDelta, uuid }
            )
        }else {
            toast.error('Time not allow  subscriptions')
            refetchEvents()
        }



    } catch (e) {
        refetchEvents()
    }
})

addReducer('changeTime', async (g, dispatch, eventDropInfo) => {
    // console.log(eventResizeInfo)

    try {
        const { delta, event: {start, end, extendedProps: { uuid ,subscriptions } } } = eventDropInfo
        // console.log(endDelta, uuid)
        if (ScheduleChangeable(start,end,subscriptions)){
            await axios.put('/schedule/changeTime',
                { delta, uuid }
            )
        }else {
            toast.error('Time not allow  subscriptions')
            refetchEvents()
        }

    } catch (e) {
        refetchEvents()
    }
})
